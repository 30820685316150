<button class="closeModalButton" (click)="close()"> x </button>
<div class="profile">
  <div class="header">
    <img *ngIf="data.picture" [src]="data.picture" [alt]="data.name">
    <h3>{{ data?.name }}</h3>
    <h5>{{ data?.email }}</h5>
  </div>
  <div class="body">
    <div *ngIf="profileState === 'general'">
      <h4>{{ 'CONECTED_VEHICLES' | translation }}</h4>
      <h4 style="color: #676666">{{ vehicles.length }}</h4>
      <br>
      <div class="admin-config-wrapper" *ngIf="gs.checkPermission(['admin.fleetmgr'])">
        <button class="outlined-button full-width" mat-stroked-button
          (click)="updateState('adminConfiguration')">
          {{ 'ADMIN_CONFIGURATION.TITLE' | translation }}
        </button>
        <br>
        <br>
      </div>
      <div class="admin-config-wrapper" *ngIf="gs.checkPermission(['admin.fleetmgr']) && showSelftManagementButton">
        <a routerLink="home/self-management" class="outlined-button full-width" mat-stroked-button (click)="close()">
          {{ 'ONBOARDING.SELF_MANAGEMENT' | translation }}
        </a>
        <br>
        <br>
      </div>
      <button *ngIf="gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])"
        class="outlined-button full-width" mat-stroked-button
        (click)="updateState('fleetConfiguration')">
        {{ 'FLEET_CONFIGURATION.TITLE' | translation }}
      </button>
      <br>
      <br>
      <button class="outlined-button full-width" mat-stroked-button
        (click)="changePassword(data?.email)">
        {{ 'CHANGE_PASSWORD_IN_APP' | translation }}
      </button>
      <br>
      <br>
      <button [disabled]="disableLogout" class="outlined-button full-width"
        mat-stroked-button (click)="logOutModal()">
        {{ 'LOGOUT' | translation }}
      </button>
    </div>
    <div *ngIf="profileState === 'changePassword'">
      <app-change-password [comingFrom]="'profile'" [admin]="data"
        (backToProfile)="updateState($event)"></app-change-password>
    </div>
    <div *ngIf="profileState === 'fleetConfiguration'">
      <app-fleet-configuration [admin]="data"
        (backToProfile)="updateState($event)"></app-fleet-configuration>
    </div>
    <div *ngIf="profileState === 'adminConfiguration'">
      <app-admin-configuration [admin]="data"
        (backToProfile)="updateState($event)"></app-admin-configuration>
    </div>

  </div>

</div>

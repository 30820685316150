import { ErrorHandler, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DriverTemplateComponent } from './components/finder-lists/driver-template/driver-template.component'
import { FinderListsComponent } from './components/finder-lists/finder-lists.component'
import { GeofenceTemplateComponent } from './components/finder-lists/geofence-template/geofence-template.component'
import { VehicleTemplateComponent } from './components/finder-lists/vehicle-template/vehicle-template.component'
import { FinderComponent } from './components/finder/finder.component'
import { NotificationTemplateComponent } from './components/notifications/notification-template/notification-template.component'
import { SideInfoWindowComponent } from './components/side-info-window/side-info-window.component'
import { SideToolBarComponent } from './components/side-tool-bar/side-tool-bar.component'
import { SpinnerComponent } from './components/spinner/spinner.component'

import { CommonModule, registerLocaleData } from '@angular/common'
import localeDeAt from '@angular/common/locales/es-CL'
registerLocaleData(localeDeAt);

import { HttpClientModule } from '@angular/common/http'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { ServiceWorkerModule } from '@angular/service-worker'
import { JwtModule } from '@auth0/angular-jwt'
import { NgCircleProgressModule } from 'ng-circle-progress'
import { NgxStarsModule } from 'ngx-stars'
import { TimeagoModule } from 'ngx-timeago'
import { TranslationModule } from '../app/pipes/translation/translation.module'
import { environment as env } from '../environments/environment'
import { AlertSnackBarComponent } from './components/alert-snack-bar/alert-snack-bar.component'
import { CreatingEntityComponent } from './components/creating-entity/creating-entity.component'
import { FormsComponent } from './components/creating-entity/forms/forms.component'
import { SharedDashboardModule } from './components/dashboard/shared-dashboard.module'
import { DriverAdviceComponent } from './components/driver-advice/driver-advice.component'
import { FeedbackAlertModalComponent, FeedbackComponent } from './components/feedback/feedback.component'
import { AdminTemplateComponent } from './components/finder-lists/admin-template/admin-template.component'
import { DeviceTemplateComponent } from './components/finder-lists/device-template/device-template.component'
import { EditDriverComponent } from './components/finder-lists/driver-template/edit-driver/edit-driver.component'
import { DeleteGeofenceComponent } from './components/finder-lists/geofence-template/delete-geofence/delete-geofence.component'
import { GeofencingCreateComponent } from './components/finder-lists/geofence-template/geofencing-create/geofencing-create.component'
import { GeofencingTemplateComponent } from './components/finder-lists/geofence-template/geofencingvehicles-template/geofencingvehicles-template.component'
import { GeneralModalComponent } from './components/general-modal/general-modal.component'
import { GeofenceSortingComponent } from './components/geofence-sorting/geofence-sorting.component'
import { HealthDetailComponent } from './components/health-list/health-detail/health-detail.component'
import { HealthListComponent } from './components/health-list/health-list.component'
import { MobileViewComponent } from './components/mobile-view/mobile-view.component'
import { NotificationSettingsComponent } from './components/notifications/notification-settings/notification-settings.component'
import { NotificationsComponent } from './components/notifications/notifications.component'
import { FiltersComponent } from './components/performance/filters/filters.component'
import { KpisComponent } from './components/performance/kpis/kpis.component'
import { PerformanceComponent } from './components/performance/performance.component'
import { ScoreInfoComponent } from './components/performance/score-info/score-info.component'
import { ScoreComponent } from './components/performance/score/score.component'
import { ChangePasswordModule } from './components/profile/change-password/change-password.module'
import { FleetConfigurationComponent } from './components/profile/fleet-configuration/fleet-configuration.component'
import { ProfileComponent } from './components/profile/profile.component'
import { RankingListComponent } from './components/ranking/ranking-template/ranking-template.component'
import { RankingTemplateComponent } from './components/ranking/ranking.component'
import { StatsTemplateComponent } from './components/stats/stats-template/stats-template.component'
import { StatsListComponent } from './components/stats/stats.component'
import { TripsListComponent } from './components/trips-list/trips-list.component'
import { VehicleHealthComponent } from './components/vehicle-health/vehicle-health.component'
import { VehicleMaintenanceComponent } from './components/vehicle-maintenance/vehicle-maintenance.component'
import { VehicleSortingComponent } from './components/vehicle-sorting/vehicle-sorting.component'
import { generalFiltersPipe } from './pipes/generalFiltersPipe.module'

import { AutocompleteLibModule } from 'angular-ng-autocomplete'
import { MarkdownModule } from 'ngx-markdown'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { DashcamDateRangeModalComponent } from './components/dashcam-date-range-modal/dashcam-date-range-modal.component'
import { DetailDtcsComponent } from './components/detail-dtcs/detail-dtcs.component'
import { DriversListExtensionsComponent } from './components/extensions-list/drivers-list-extensions/drivers-list-extensions.component'
import { ExtensionDetailComponent } from './components/extensions-list/extension-detail/extension-detail.component'
import { ExtensionsActivateSuccessComponent } from './components/extensions-list/extensions-activate-success/extensions-activate-success.component'
import { ExtensionsActivateComponent } from './components/extensions-list/extensions-activate/extensions-activate.component'
import { ExtensionsListComponent } from './components/extensions-list/extensions-list.component'
import { DashcamTemplateComponent } from './components/finder-lists/dashcam-template/dashcam-template.component'
import { EditVehicleComponent } from './components/finder-lists/vehicle-template/edit-vehicle/edit-vehicle.component'
import { VehicleAddTagsComponent } from './components/finder-lists/vehicle-template/vehicle-add-tags/vehicle-add-tags.component'
import { VehicleTagsSectionComponent } from './components/finder-lists/vehicle-template/vehicle-tags-section/vehicle-tags-section.component'
import { AdminConfigurationComponent } from './components/profile/admin-configuration/admin-configuration.component'
import { DeleteAdminComponent } from './components/profile/admin-configuration/delete-admin/delete-admin.component'
import { ChangePasswordResponseComponent } from './components/profile/modify-password/change-password-response/change-password-response.component'
import { ModifyPasswordComponent } from './components/profile/modify-password/modify-password.component'
import { WeekFilterComponent } from './components/ranking/week-filter/week-filter.component'
import { ReportsModalComponent } from './components/reports-modal/reports-modal.component'
import { ScoreScheduleModalComponent } from './components/score-schedule-modal/score-schedule-modal.component'
import { TagDeleteAlertComponent } from './components/tag/tag-delete-alert/tag-delete-alert.component'
import { TagComponent } from './components/tag/tag.component'
import { TripEventsComponent } from './components/trips-list/trip-events/trip-events/trip-events.component'
import { DeleteMaintenanceComponent } from './components/vehicle-maintenance/delete-maintenance/delete-maintenance.component'
import { EditMaintenanceComponent } from './components/vehicle-maintenance/edit-maintenance/edit-maintenance.component'
import { ValidateHigherOdometerUpdateComponent } from './components/vehicle-maintenance/validate-higher-odometer-update/validate-higher-odometer-update.component'
import { ValidateOdometerUpdateComponent } from './components/vehicle-maintenance/validate-odometer-update/validate-odometer-update.component'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { VideoFLVThumbnailPlayerComponent } from './components/videoflv-thumbnail-player/videoflv-thumbnail-player.component'
import { VideoTSPlayerComponent } from './components/videots-player/videots-player.component'
import { VideoTSThumbnailPlayerComponent } from './components/videots-thumbnail-player/videots-thumbnail-player.component'
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './rollbar'

export function tokenGetter() {
  return localStorage.getItem('id');
}

let providers = [
  { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
];

if (env.production) {
  const errorHandler: any = { provide: ErrorHandler, useClass: RollbarErrorHandler };
  const rollbarService: any = { provide: RollbarService, useFactory: rollbarFactory };
  const rollbarProviders = [errorHandler, rollbarService];
  providers = [...providers, ...rollbarProviders];
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    SideToolBarComponent,
    ProfileComponent,
    SideInfoWindowComponent,
    FinderComponent,
    FinderListsComponent,
    NotificationsComponent,
    DriverTemplateComponent,
    VehicleTemplateComponent,
    GeofenceTemplateComponent, NotificationTemplateComponent,
    AdminTemplateComponent, DeviceTemplateComponent, TripsListComponent,
    RankingListComponent, RankingTemplateComponent,
    StatsTemplateComponent, StatsListComponent,
    ProfileComponent, GeneralModalComponent, MobileViewComponent,
    VehicleHealthComponent,
    NotificationSettingsComponent,
    NotificationsComponent,
    VehicleSortingComponent,
    HealthListComponent,
    HealthDetailComponent,
    VehicleMaintenanceComponent,
    CreatingEntityComponent,
    FormsComponent,
    DriverAdviceComponent,
    KpisComponent,
    FiltersComponent,
    PerformanceComponent,
    ScoreComponent,
    AlertSnackBarComponent,
    ScoreInfoComponent,
    FeedbackComponent,
    FeedbackAlertModalComponent,
    FleetConfigurationComponent,
    EditDriverComponent,
    EditVehicleComponent,
    GeofencingTemplateComponent,
    DeleteGeofenceComponent,
    GeofencingCreateComponent,
    GeofenceSortingComponent,
    WeekFilterComponent,
    AdminConfigurationComponent,
    DeleteAdminComponent,
    ReportsModalComponent,
    EditMaintenanceComponent,
    DeleteMaintenanceComponent,
    ValidateOdometerUpdateComponent,
    ValidateHigherOdometerUpdateComponent,
    VehicleTagsSectionComponent,
    VehicleAddTagsComponent,
    TagComponent,
    TagDeleteAlertComponent,
    ModifyPasswordComponent,
    ChangePasswordResponseComponent,
    DetailDtcsComponent,
    ExtensionsListComponent,
    ExtensionDetailComponent,
    DriversListExtensionsComponent,
    ExtensionsActivateComponent,
    ExtensionsActivateSuccessComponent,
    TripEventsComponent,
    DashcamDateRangeModalComponent,
    VideoTSPlayerComponent,
    VideoTSThumbnailPlayerComponent,
    VideoFLVThumbnailPlayerComponent,
    ScoreScheduleModalComponent,
    DashcamTemplateComponent,
    VideoPlayerComponent,
  ],
  entryComponents: [
    ProfileComponent,
    GeneralModalComponent,
    ReportsModalComponent,
    FormsComponent,
    AlertSnackBarComponent,
    FeedbackAlertModalComponent,
    WeekFilterComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    SharedDashboardModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxStarsModule,
    TimeagoModule.forRoot(),
    TranslationModule,
    generalFiltersPipe,
    NgCircleProgressModule.forRoot({
      radius: 30,
      space: -5,
      outerStrokeWidth: 5,
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 5,
      animationDuration: 300,
      showSubtitle: false,
      unitsFontSize: '18',
      titleFontSize: '18'
    }),
    // Material modules
    MatToolbarModule, MatIconModule, MatButtonModule, MatSidenavModule, MatMenuModule, MatRadioModule,
    MatSelectModule, MatListModule, MatExpansionModule, MatProgressSpinnerModule, MatSlideToggleModule,
    MatSnackBarModule, MatDialogModule, ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
    MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatTooltipModule,
    ChangePasswordModule, MatGridListModule, MatCardModule,
    MarkdownModule.forRoot(),
    NgxMaterialTimepickerModule
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, Input, OnInit } from '@angular/core'
import { DriverService } from '../../../../services/driver.service'
import { GeneralService } from '../../../../services/general.service'
import { RequestService } from '../../../../services/request.service'

@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit {

  @Input('data') driver: any;
  @Input('dialogRef') dialogRef: any;
  drivers: any;
  currentDriver: any;

  constructor(
    private rs: RequestService,
    private gs: GeneralService,
    private ds: DriverService
  ) {
    this.ds.getCurrentDrivers.subscribe(drivers => this.drivers = drivers);
  }

  ngOnInit() {
    this.currentDriver = {
      firstName: this.driver.firstName,
      lastName: this.driver.lastName,
      email: this.driver.email,
      phone: this.driver.phone
    }
  }

  async getDrivers() {
    return await this.rs.request({
      method: 'GET',
      endpoint: 'get_drivers'
    });
  }

  async saveDriver() {
    if (this.pristineCheck()) {
      return;
    }

    const body = {
      firstName: this.driver.firstName,
      lastName: this.driver.lastName,
      email: this.driver.email,
      phone: this.driver.phone || '',
    };

    const res = await this.rs.request({
      method: 'PUT',
      endpoint: 'update_driver',
      driverId: this.driver.id,
      body
    });

    if ( res.error ) {
      return this.gs.openAlert({ msg: res.error.localizedMessage, error: true });
    }

    this.gs.openAlert({ msg: 'USER_SAVED' });
    this.backToList();
    this.gs.logScreenEvents('driver_click_save', 'driver_click_save', 'driver_click_save', 'click');
  }

  async backToList() {
    this.dialogRef.close();
  }

  pristineCheck() {
    return  this.currentDriver.firstName === this.driver.firstName &&
            this.currentDriver.lastName === this.driver.lastName &&
            this.currentDriver.email === this.driver.email &&
            this.currentDriver.phone === this.driver.phone;
  }

}

import {Component, ElementRef, Input, Output, OnDestroy, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {VideoWaitComponent} from '../dashcam-date-range-modal/video-wait/video-wait.component';
import {translationPipe} from '../../pipes/translation/translation.pipe';
import {GeneralService} from '../../services/general.service';


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer', {static: true}) videoPlayer!: ElementRef;
  @Input() selectedVideo: string;
  @Output() myEvent = new EventEmitter<void>();
  retryCount = 0;
  maxRetries = 1; // Número máximo de reintentos
  retryDelay = 3000; // Tiempo de espera en milisegundos (3 segundos)
  playVideo() {
      const videoElement = this.videoPlayer.nativeElement;
      videoElement.src = this.selectedVideo;
      videoElement.play();
  }
  constructor(
    public dialog: MatDialog,
    private gs: GeneralService
  ) {}

  ngOnInit() {
    this.playVideo();
  }

  ngOnDestroy() {
  }
  handleVideoError(event: Event) {
    const videoElement = this.videoPlayer.nativeElement;
    const error = event ? (event.target as HTMLVideoElement).error : null;
    if (error && this.retryCount < this.maxRetries) {
      if (error.code === 4) {
        this.retryCount++;
        this.retryPlayVideo();
      }
    }/* else {
      alert('Error al cargar el video: ' + this.getErrorMessage(error));
    }*/
  }
  retryPlayVideo() {
    this.openDialog();
  }

  getErrorMessage(error: MediaError): string {
    switch (error.code) {
      case error.MEDIA_ERR_ABORTED:
        return 'La reproducción del video fue cancelada.';
      case error.MEDIA_ERR_NETWORK:
        return 'Ocurrió un error de red durante la descarga del video.';
      case error.MEDIA_ERR_DECODE:
        return 'Ocurrió un error al decodificar el video.';
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        return 'No se encontró una fuente de video compatible.';
      default:
        return 'Ocurrió un error desconocido.';
    }
  }
  openDialog(): void {
    const _this = this;
    const dialogRef = this.dialog.open(VideoWaitComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.retryCount = 0;
        let start = 30;
        this.gs.openFleetrMsg({msg: translationPipe.transform('DASHCAM_TEXT.REQUEST_UPLOAD_VIDEO_PATH') + start + 's.', duration: 30000});
        const updateTimer = document.getElementsByTagName('app-alert-snack-bar');
        const temp = setInterval(function () {
          start--;
          updateTimer[0].innerHTML = translationPipe.transform('DASHCAM_TEXT.REQUEST_UPLOAD_VIDEO_PATH') + start + 's.';
        }, 1000);
        setTimeout(function () {
          clearInterval(temp);
          const videoElement = _this.videoPlayer.nativeElement;
          videoElement.load(); // Recarga el video
          videoElement.play().catch(error => {
            if (error.code === 4) {
              _this.retryPlayVideo();
            }
          });
        }, 30000);
      }
      if (result === 'close') {
        this.myEvent.emit();
      }
    });
  }
}

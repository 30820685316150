<mat-expansion-panel [id]="'vehicle_'+selected?.plate" [expanded]="selected?.id == vehicle?.id && !isFreemium"
  (closed)="selectedVehicle(undefined, null)">
  <mat-expansion-panel-header #panelH
    [ngClass]="{
      'selected-driver-freemium': (selected?.id === vehicle?.id) && isFreemium
    }"
    (click)="selectedVehicle(vehicle, panelH)"
    collapsedHeight="80px" expandedHeight="80px">
    <mat-list class="entity-list">
      <mat-list-item>
        <!-- Picture -->
        <div class="current-driver">
          <div class="driver-picture" *ngIf="vehicle.current_driver" [ngStyle]="{ 'background': vehicle.current_driver?.picture ?
              'url('+vehicle.current_driver?.picture+')' : '#ffe09d'}">
            <span class="noPicture" *ngIf="!vehicle.current_driver?.picture">
              {{ vehicle.current_driver ? getNameFirstLetter(vehicle.current_driver?.name,
              vehicle.current_driver?.lastName) : ''}}
            </span>
            <span class="state" [ngStyle]="{ 'color': vehicle.currentStatus === 'TripDriving' ? '#38b6d1' : '' }">
              <span *ngIf="vehicle.currentStatus === 'TripDriving'" class="driving"></span>
              <span *ngIf="vehicle.currentStatus === 'TripStopped'">{{ 'STOPPED' | translation }} </span>
              <span *ngIf="vehicle.currentStatus === 'Unknown' || vehicle.currentStatus === 'Disconnected'"
                style="color:#b3b3b3;">
                {{ 'NO_SIGNAL' | translation }}
              </span>
              <span *ngIf="!vehicle.currentStatus" style="color:#b3b3b3;">
                {{ (vehicle.device?.status ? 'DEVICE_STATUS.'+ vehicle.device?.status : 'NO_DEVICE') | translation }}
              </span>
            </span>
          </div>
        </div>

        <mat-icon mat-list-icon class="picture hasPicture"
          [ngStyle]="{ 'background': vehicle.brand ? vehicle.picture ? 'url('+vehicle.picture+')' : '#ffe09d' : '#a6a6a6'}">
          <span class="brand" [ngStyle]="{'color': vehicle.brand ? '' : '#ffffff'}">
            {{ vehicle.brand ? vehicle.brand.slice(0, 1)[0] : '?' }}
          </span>

          <span class="indicador-driver" [ngClass]="{
              'indicator-driving': vehicle.currentStatus === 'TripDriving',
              'indicator-stopped': vehicle.currentStatus !== 'TripDriving'
            }">
          </span>

          <img *ngIf="sort === 'status'" class="health" [src]="'assets/img/'+vehicle.status+'.svg'" alt="health">
        </mat-icon>

        <h4 class="name" mat-line>
          <span *ngIf="vehicle.plate" class="plate" innerHTML="{{ vehicle?.plate | highlight: findText}}"></span>
        </h4>

        <p class="shift" mat-line>
          <span innerHTML="{{ vehicle?.brand | highlight: findText}}"></span>&nbsp;
          <span innerHTML="{{ vehicle?.model | highlight: findText}}"></span>
          <span *ngIf="vehicle.year"> - </span>
          <span class="year" innerHTML="{{ vehicle?.year | highlight: findText}}"></span>
        </p>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel-header>
  <!-- Collapse content -->
  <div class="container">
    <div class="list-section">
      <p class="p-splitted" *ngIf="gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
        <button class="edit-vehicle" mat-stroked-button (click)="editVehicle(vehicle)">
          {{ 'VEHICLE_ADD_INFO' | translation }}
        </button>
      </p>
      <p class="p-splitted">
        <button class="outlined-button" mat-stroked-button (click)="openHealth()">
          <img class="vehicle-status" [src]="'assets/img/'+vehicle.status+'.svg'" alt="health">
          {{ vehicle.status | translation }}
        </button>
      </p>
      <p class="p-splitted">
        <button class="outlined-button maintennce-button" mat-stroked-button (click)="openMaintenance()">
          <img class="vehicle-maintenance" [src]="'assets/img/maintenance/maintenance-icon.svg'" alt="maintenance">
          {{ 'MAINTENANCE.TITLE' | translation }}
        </button>
      </p>
      <p *ngIf="vehicle.dashcam" class="p-splitted">
        <button class="outlined-button" mat-raised-button (click)="getDashCam(vehicle.dashcam)"> {{'DASHCAM' | translation}}
        </button>
      </p>
      <p class="p-splitted">
        <button class="outlined-button" mat-raised-button (click)="openTrips()"> {{ 'TRIPS' | translation }}
        </button>
      </p>
    </div>

    <!-- Tags Section -->
    <mat-list class="template-list">
      <mat-list class="template-list">
        <app-vehicle-tags-section [vehicle]="vehicle" (addOpen)="showOptions()"></app-vehicle-tags-section>
        <app-vehicle-add-tags
          *ngIf="showAddVehicleTags"
          [vehicle]="vehicle"
          (updated)="addTagsUpdated()"
          (close)="addTagsClose()"></app-vehicle-add-tags>
      </mat-list>
    </mat-list>
    <!-- End Tags Section -->

    <mat-divider></mat-divider>
    <mat-list class="template-list">
      <!-- device -->
      <h3 class="vehicle-subheader" matSubheader>{{ 'DEVICE' | translation }}</h3>
      <mat-list-item class="vehicle-device" style=" margin-bottom: 20px; ">
        <mat-icon mat-list-icon class="picture hasPicture" style="background: #ffe09d">
          <span class="noPicture"> {{ vehicle.device?.model }} </span>
          <span class="indicador-device"> </span>
        </mat-icon>
        <h4 class="name" mat-line>{{vehicle.device?.imei}}</h4>
        <p class="shift" mat-line>
          {{ (vehicle.device?.status ? 'DEVICE_STATUS.'+ vehicle.device?.status : 'NO_DEVICE') | translation}}
        </p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <!-- driver -->
      <h3 class="vehicle-subheader" matSubheader>{{ 'CURRENT_DRIVER' | translation }}</h3>
      <h5 *ngIf="!vehicle.current_driver" class="noshift-user"> {{ 'VEHICLE_WITHOUT_DRIVER' | translation }} </h5>
      <mat-list-item class="current-driver list-hover" *ngIf="vehicle.current_driver"
        (click)="homeService.actions({ id: vehicle.current_driver.id }, 'drivers')">
        <mat-icon mat-list-icon style="border: none !important" class="picture hasPicture"
          [ngStyle]="{ 'background': vehicle.current_driver?.picture ? 'url('+vehicle.current_driver?.picture+')' : '#ffe09d'}">
          <span class="noPicture" *ngIf="!vehicle.current_driver?.picture"> {{vehicle.current_driver ?
            getNameFirstLetter(vehicle.current_driver?.name, vehicle.current_driver?.lastName) : '' }}
          </span>
        </mat-icon>
        <h4 class="name" mat-line>
          {{ fullName(vehicle.current_driver?.name, vehicle.current_driver?.lastName) }}
        </h4>

        <p mat-line>
          <span class="state-driver" [ngStyle]="{ 'color': vehicle.currentStatus === 'TripDriving' ? '#38b6d1' : '' }">
            <span *ngIf="vehicle.currentStatus === 'TripDriving'" class="driving"></span>
            <span *ngIf="vehicle.currentStatus === 'TripDriving'" class="active-vehicle">{{ 'DRIVING' | translation }}
              {{ vehicle.movingTime }}</span>
            <span *ngIf="vehicle.currentStatus === 'TripStopped'" style="color:#b3b3b3;">{{ 'STOPPED' | translation
              }}</span>
            <span *ngIf="vehicle.currentStatus === 'Unknown' || vehicle.currentStatus === 'Disconnected'"
              style="color:#b3b3b3;">
              {{ 'NO_SIGNAL' | translation }}
            </span>
          </span>
        </p>
      </mat-list-item>
    </mat-list>
    <div class="close-panel" (click)="closePanel()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { translationPipe } from '../../../pipes/translation/translation.pipe';

@Component({
  selector: 'app-video-wait',
  templateUrl: './video-wait.component.html',
  styleUrls: ['./video-wait.component.css']
})
export class VideoWaitComponent {
  constructor(
    public dialogRef: MatDialogRef<VideoWaitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string },
  ) {}

  public readonly translationPipe = translationPipe;

  onNoClick(): void {
    this.dialogRef.close('close');
  }

  onOkClick(): void {
    this.dialogRef.close('ok');
  }
}

<button class="closeModalButton" (click)="onNoClick()"> x </button>
<div class="actionpopuup">
  <h3>{{ translationPipe.transform('CONFIRM') }}</h3>
  <span>{{ translationPipe.transform('DASHCAM_TEXT.RETRY_VIDEO') }}</span>
  <div class="actions">
    <button mat-raised-button class="outlined-button" type="button" (click)="onNoClick()"> {{ translationPipe.transform('NO') }} </button>
    <button mat-raised-button class="outlined-button" type="button" (click)="onOkClick()"> {{ translationPipe.transform('YES') }} </button>
  </div>
</div>

<!--<h1 mat-dialog-title>Mensaje</h1>
<div mat-dialog-content>
  <p>{{data.message}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
</div>-->

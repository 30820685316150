import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment as env, environment} from '../../environments/environment';
import { HEADER_OPTIONS } from '../helpers/helpers';
import {BehaviorSubject, Observable} from 'rxjs';
import {Dashcam} from '../models/dashcam.model';
import {map} from 'rxjs/operators';
import {TagService} from './tag.service';

@Injectable({
  providedIn: 'root'
})
export class  DashcamService {

  dashcams = new BehaviorSubject<Dashcam.Dashcam[]>([]);
  getCurrentDashcams: Observable<Dashcam.Dashcam[]> = this.dashcams.asObservable();

  setCurrentDashcams(dashcams: Dashcam.Dashcam[]) {
    const diff = this.dashcams.getValue().filter(dashcam => dashcams.find(v => v.id === dashcam.id) === undefined);
    this.dashcams.next([...diff, ...dashcams]);
  }

  constructor(public http: HttpClient, private tagService: TagService) {
    this.tagService.appliedTag$.subscribe(() => {
      // Force to reload map when tags change
      const curr = this.dashcams.getValue();
      this.dashcams.next(curr);
    });
  }

  getAll(): Observable<Dashcam.Dashcam[]> {
    return this.http
      .get(`${env.API_PATH}/api/vehicle/v1/all`, HEADER_OPTIONS()) as Observable<Dashcam.Dashcam[]>;
  }

  getAllDashcams() {
    return this.http
      .get(`${environment.API_BASE_URL}/v1/core/dashcams`, HEADER_OPTIONS());
  }

  getDashcamVehicle(vehicleId: string) {
    return this.http.get(`${environment.API_BASE_URL}/v1/core/vehicles/${vehicleId}/dashcam`, HEADER_OPTIONS());
  }

  getDashCode(camImei: string, payload: any) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/dashcanAlert`, payload, HEADER_OPTIONS());
  }

  getSignedUrl(camImei: string, filePath: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/dashcanAlertSignedMediaFiles`, {filePath}, HEADER_OPTIONS());
  }

  getLiveFeedUrl(camImei: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/getLiveFeedUrl`, HEADER_OPTIONS());
  }

  uploadVideoFiles(camImei: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/uploadFiles`, HEADER_OPTIONS());
  }

  getFilesAvailables(camImei: string, payload: any) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/getHVideoFiles`,  payload,  HEADER_OPTIONS());
  }

  getFileInServer(camImei: string, fileName: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/startHVideoUpload`,  {fileName}, HEADER_OPTIONS());
  }

  getSignedUrlVideoOnDemand( files: any) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/getSignedMediaUrl`, {files}, HEADER_OPTIONS());
  }

  getDeviceStatus(camImei: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/${camImei}/getDeviceStatus`, HEADER_OPTIONS());
  }

  getAllDevice(camImei: string) {
    return this.http.post(`${environment.API_BASE_URL}/v1/core/matracks/getDevices`, HEADER_OPTIONS());
  }

  getAnyUrl(Url: string) {
    return this.http.get(Url);
  }
}

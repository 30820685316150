<button class="closeModalButton" (click)="close()"> x</button>
<div class="profile">
  <div class="header">
    <h3>{{ 'DASHCAM_TEXT.INPUT_DATA_MODAL' | translation }}</h3>
  </div>
  <div class="body">
    <div class="temp" *ngIf="!liveFeed && !selectedPath; else liveFeedDiv">
      <div class="divEventsVideoOndemand">
        <div class="divEvents" *ngIf="state === 'inputData'"
             [ngClass]="{
              'background-focus': videoOnDemand === false,
              'background-no-focus': videoOnDemand === true
            }">
          <h4>{{ 'DASHCAM_TEXT.RANGE_TITLE' | translation }}</h4>
          <br>

          <div class="date-picker">
            <div class="item">
              <span>{{ 'FROM' | translation }}</span>
              <mat-form-field>
                <input class="date-input" disabled matInput [matDatepicker]="picker1" [(ngModel)]="datePicker.from"
                       [min]="rangeFrom.min" [value]="datePicker.from" [max]="rangeFrom.max">
                <mat-datepicker-toggle disabled="false" matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #picker1></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="item">
              <span>{{ 'TO' | translation }}</span>
              <mat-form-field>
                <input class="date-input" disabled matInput [matDatepicker]="picker2" [(ngModel)]="datePicker.to"
                       [min]="rangeTo.min" [value]="datePicker.to" [max]="rangeTo.max">
                <mat-datepicker-toggle disabled="false" matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #picker2></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="buttons-wrapper">
              <button class="outlined-button" [disabled]="request" mat-stroked-button
                      (click)="getRangeVideos()">
                {{ 'DASHCAM_TEXT.GET_VIDEOS' | translation }}
              </button>
            </div>
          </div>
        </div>
        <div class="divVideoOndemand" *ngIf="state === 'inputData'"
             [ngClass]="{
              'background-focus': videoOnDemand === true,
              'background-no-focus': videoOnDemand === false
            }">
          <h4>{{ 'DASHCAM_TEXT.VIDEO_ONDEMAND' | translation }}</h4>
          <br>
          <div class="date-picker">

            <div class="item">
              <span>{{ 'FROM' | translation }}</span>
              <mat-form-field>
                <input class="date-input" disabled matInput [matDatepicker]="picker3" [(ngModel)]="datePickerVideo.from"
                       [min]="rangeFrom.min" [value]="datePickerVideo.from" [max]="rangeFrom.max">
                <mat-datepicker-toggle disabled="false" matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #picker3></mat-datepicker>
              </mat-form-field>
              <input class="time-input" placeholder="Select a time" aria-label="12hr format"
                     [ngxTimepicker]="timeFrom" [value]="timePickerVideo.from" [(ngModel)]="timePickerVideo.from" readonly>
              <ngx-material-timepicker #timeFrom [theme]="darkTheme"></ngx-material-timepicker>
            </div>

            <div class="item">
              <span>{{ 'TO' | translation }}</span>
              <mat-form-field>
                <input class="date-input" disabled matInput [matDatepicker]="picker4" [(ngModel)]="datePickerVideo.to"
                       [min]="rangeTo.min" [value]="datePickerVideo.to" [max]="rangeTo.max">
                <mat-datepicker-toggle disabled="false" matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker disabled="false" touchUi #picker4></mat-datepicker>
              </mat-form-field>
              <input class="time-input" placeholder="Select a time" aria-label="12hr format"
                     [ngxTimepicker]="timeTo" [value]="timePickerVideo.to" [(ngModel)]="timePickerVideo.to" readonly>
              <ngx-material-timepicker #timeTo [theme]="darkTheme"></ngx-material-timepicker>
            </div>

            <div class="buttons-wrapper">
              <button class="outlined-button" [disabled]="request" mat-stroked-button
                      (click)="getRangeVideosOndemand()">
                {{ 'DASHCAM_TEXT.GET_VIDEOS' | translation }}
              </button>
            </div>
          </div>
        </div>
        <div class="divLiveFeedUrl" *ngIf="state === 'inputData'">
          <h4>{{ 'DASHCAM_TEXT.LIVE_FEED' | translation }}</h4>
          <br>

          <div class="buttons-wrapper getLiveVideoButton">
            <button class="outlined-button" [disabled]="request" mat-stroked-button
                    (click)="getLiveFeed()">
              {{ 'DASHCAM_TEXT.GET_LIVE_FEED' | translation }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="overflowShow" *ngIf="!videoOnDemand && !liveFeed && !selectedPath">
      <h4 class="titleCenter">{{ 'DASHCAM_TEXT.EVENTS_LIST' | translation }}</h4>
      <table *ngIf="!loadingEvents && events.length > 0">
        <thead>
        <tr>
          <th>{{ 'DASHCAM_TEXT.EVENT_DATE' | translation }}</th>
          <th>{{ 'DASHCAM_TEXT.EVENT_TIME' | translation }}</th>
          <th>{{ 'DASHCAM_TEXT.EVENT_NAME' | translation }}</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let event of events">
          <td>{{ event.tripDate }}</td>
          <td>{{ event.time }}</td>
          <td>{{ event.eventName }}</td>
          <td class="download-section">
            <button
              *ngIf="!getLinksSpinner[event.filePath]"
              class="download-button"
              (click)="downloadSignedUrl(event.filePath)">
              {{ 'DASHCAM_TEXT.GET_LINKS' | translation }}
            </button>

            <div class="get-link-spinner" *ngIf="getLinksSpinner[event.filePath]">
              <mat-spinner></mat-spinner>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="showVideoOndemand" *ngIf="videoOnDemand && !liveFeed">
      <!--<div class="comingSoon">
        <p>{{ 'DASHCAM_TEXT.COMING_SOON' | translation }}</p>
      </div>-->
      <h4 class="titleCenter">{{ 'DASHCAM_TEXT.VIDEO_LIST' | translation }}</h4>
      <table *ngIf="!loadingEvents && videos.length > 0">
        <thead>
        <tr>
          <th>{{ 'DASHCAM_TEXT.VIDEO_DATE' | translation }}</th>
          <th>{{ 'DASHCAM_TEXT.VIDEO_TIME' | translation }}</th>
          <th>{{ 'DASHCAM_TEXT.DEVICE_TYPE' | translation }}</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let video of videos">
          <td>{{ video.videoDate }}</td>
          <td>{{ video.videoTime }}</td>
          <td>{{ video.deviceType }}</td>
          <td class="download-section">
            <button
              *ngIf="!getLinksVideoSpinner[video.file]"
              class="download-button"
              (click)="downloadSigneVideoUrl(video.file, video.status, video.filePath)">
              {{ 'DASHCAM_TEXT.GET_LINKS' | translation }}
            </button>

            <div class="get-link-spinner" *ngIf="getLinksVideoSpinner[video.file]">
              <mat-spinner></mat-spinner>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  <div *ngIf="loadingEvents" class="loading-events">
    <mat-spinner></mat-spinner>
  </div>
</div>


<ng-template #videoDisplay>
  <div class="container" *ngIf="isDashVideo; else noVideoPlayer">
    <div class="video-container">
      <div class="main-video">
        <div *ngIf="isMP4; else videoPlayerTS">
          <div class="video-player">
            <video class="player" [src]="selectedVideo" loop controls autoplay></video>
          </div>
        </div>
        <ng-template #videoPlayerTS>
          <div class="video-player">
            <app-videots-player [selectedVideo]="selectedVideo"></app-videots-player>
          </div>
        </ng-template>
        <div class="button-wrapper">
          <button class="outlined-button" (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
            <p>Back</p>
          </button>
          <div class="label">
            <mat-icon>download</mat-icon>
            {{ 'DASHCAM_TEXT.DOWNLOAD_VIDEOS' | translation }}:
          </div>
          <select class="selected" [(ngModel)]="videoDownload" (ngModelChange)="sencVideoDownload(videoDownload)">
            <option value="Select One …">{{ 'DASHCAM_TEXT.SELECT_ONE' | translation }}</option>
            <option *ngFor="let video of getVideos(selectedPath)" [value]="video">{{ getNameVideo(video) }}</option>
          </select>
        </div>
      </div>
      <div class="suggested-videos">
        <div *ngFor="let video of getVideos(selectedPath)" class="suggested-video">
          <div *ngIf="isMP4; else videoSuggestedPlayerTS">
            <div (click)="selection(video)">
              <video class="video-player" [src]="video"></video>
            </div>
          </div>
          <ng-template #videoSuggestedPlayerTS>
            <div (click)="selection(video)">
              <app-videots-thumbnail-player [selectedVideo]="video"></app-videots-thumbnail-player>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #liveFeedDiv>
  <div class="container" *ngIf="liveFeed; else videoDisplay">
    <div class="video-container">
      <div class="main-video">
        <div class="video-player">
          <app-videots-player [selectedVideo]="selectedliveFeed"></app-videots-player>
        </div>
        <div class="button-wrapper">
          <button class="outlined-button" (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
            <p>Back</p>
          </button>
        </div>
      </div>
      <div class="suggested-videos">
        <div *ngFor="let channel of liveChannels" class="suggested-video">
          <div>
            <div (click)="selectionLiveChannel(channel)">
              <app-videoflv-thumbnail-player [selectedVideo]="channel"></app-videoflv-thumbnail-player>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #noVideoPlayer>
  <div class="container" *ngIf="isDashVideoOnDemand">
    <div class="video-container">
      <div class="main-video">
        <div>
          <div class="video-player">
            <app-video-player class="player" [selectedVideo]="selectedVideoOnDemand" (myEvent)="handleEvent()" loop controls autoplay></app-video-player>
          </div>
        </div>
        <div class="button-wrapper">
          <button class="outlined-button" (click)="backVideoOnDemand()">
            <mat-icon>arrow_back_ios</mat-icon>
            <p>Back</p>
          </button>
          <div class="label">
            <mat-icon>download</mat-icon>
            {{ 'DASHCAM_TEXT.DOWNLOAD_VIDEOS' | translation }}:
          </div>
          <select class="selected" [(ngModel)]="videoDownload" (ngModelChange)="sencVideoDownload(videoDownload)">
            <option value="Select One …">{{ 'DASHCAM_TEXT.SELECT_ONE' | translation }}</option>
            <option *ngFor="let video of getVideosOnDemand(selectedPathVideo)" [value]="video">{{ getNameVideo(video) }}</option>
          </select>
        </div>
      </div>
      <div class="suggested-videos">
        <div *ngFor="let video of getVideosOnDemand(selectedPathVideo)" class="suggested-video">
          <div >
            <div (click)="selectionVideo(video)">
              <video class="video-player" [src]="video"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

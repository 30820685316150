import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { timeAgo } from '../../../helpers/helpers';
import { GeneralService } from '../../../services/general.service';
import { HomeService } from '../../../services/home.service';
import { MarkerService } from '../../../services/marker.service';
import { GeneralModalComponent } from '../../general-modal/general-modal.component';
import {DashcamService} from '../../../services/dashcam.service';

@Component({
  selector: 'dashcam-template',
  templateUrl: './dashcam-template.component.html',
  styleUrls: ['./dashcam-template.component.css']
})
export class DashcamTemplateComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() dashcam: any;
  @Input() isFreemium: boolean;
  @Output() runEntities = new EventEmitter<any>();
  expanded: boolean;
  deviceStatus: boolean;

  menu: any = [];
  wiData: any;
  contexts: any;
  map: any;
  showingRoute: boolean;
  mGroup: any;
  findText: string;
  sort: any;
  selected: any;

  constructor(
    public gs: GeneralService,
    public homeService: HomeService, // Unused, consider removing
    public markerService: MarkerService,
    public dialog: MatDialog,
    private dashcamService: DashcamService
  ) {}

  ngOnInit() {
    this.gs.logScreenEvents('dashcam_view', 'dashcam_view', 'dashcam_view', 'view');
    this.getDeviceStatus();
    this.initializeSubscriptions();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeSubscriptions() {
    this.gs.getWiFindText.pipe(takeUntil(this.destroy$)).subscribe(findText => this.findText = findText);
    this.gs.getWiContext.pipe(takeUntil(this.destroy$)).subscribe((data: any) => this.contexts = data);
    this.gs.getMap.pipe(takeUntil(this.destroy$)).subscribe((data: any) => this.map = data);
    this.gs.getShowLiveViewRoute.pipe(takeUntil(this.destroy$)).subscribe(show => this.showingRoute = show);
    this.markerService.getMGroup.pipe(takeUntil(this.destroy$)).subscribe(g => this.mGroup = g);
    this.gs.getToolbarButtons.pipe(takeUntil(this.destroy$)).subscribe(buttons => this.menu = buttons);
    this.gs.getWiData.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      if (data && data.type === 'device') {
        this.selected = data;
      }
    });
    this.gs.getVehicleSort.pipe(takeUntil(this.destroy$)).subscribe(_ => this.sort = _);
  }

  getDeviceStatus() {
    this.dashcamService.getDeviceStatus(this.dashcam.device).subscribe((resp: any) => {
      if (resp.status) {
        this.deviceStatus = true;
      } else {
        this.deviceStatus = false;
      }
    });
  }

  fullName(name: string, lastName: string) {
    return `${name.split(' ')[0]} ${lastName.split(' ')[0]}`;
  }

  closePanel() {
    this.selected = undefined;
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts });
  }

  getNameFirstLetter(name: string, lastName: string) {
    return `${name?.[0] ?? ''} ${lastName?.[0] ?? ''}`; // Simplified and safer
  }

  timeAgo(time: number | string) {
    return timeAgo(time);
  }

  selectedDashcam(dashcam: any, panel: any) {
    if (this.isFreemium && panel) { panel._toggle(); }
    this.gs.setShowLiveViewRoute(false);
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts, data: null });
    if (!dashcam || !dashcam.current_device) { return this.gs.addSelectedClass(false); }
    const marker = this.mGroup.findByImei(dashcam.current_device.imei);
    marker ? this.gs.goToMarker(marker, dashcam.current_device, this.map, this.contexts) : this.gs.addSelectedClass(false);
  }

  openEditMode(panelH, driver) {
    //panelH._toggle();
    this.openGeneralModal(driver, 'edit_driver');
    this.gs.logScreenEvents('driver_click_edit', 'driver_click_edit', 'driver_click_edit', 'click');
  }

  openGeneralModal(editDevice, type) {
    const width = '600px';
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width,
      data: { ...editDevice, type }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.runEntities.emit(true);
    });
  }

  getDashCam(dashcamId: string) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '80vw',
      data: { camDevice: dashcamId, type: 'dashcam' }
    });
  }

}

import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { values } from 'lodash'
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker'
import { Subscription } from 'rxjs'
import { translationPipe } from '../../pipes/translation/translation.pipe'
import { DashcamService } from '../../services/dashcam.service'
import { GeneralService } from '../../services/general.service'

const commonMin = new Date(new Date().setFullYear((new Date().getFullYear() - 10)));
const commonMax = new Date(new Date().setDate((new Date().getDate())));

@Component({
  selector: 'app-dashcam-date-range-modal',
  templateUrl: './dashcam-date-range-modal.component.html',
  styleUrls: ['./dashcam-date-range-modal.component.css']
})
export class DashcamDateRangeModalComponent implements OnInit, OnDestroy {

  private suscription: Subscription = new Subscription;
  @Output() runEntities = new EventEmitter<any>();
  @Input() prop: string;

  state = 'inputData';
  events = [];
  videos = [];
  liveChannels = [];
  videoUrls = {};
  videoOnDemandUrls = {};
  videoOnDemand: boolean = false;
  request: boolean = false;
  liveFeed: boolean = false;
  start: number = 20;
  selectedVideo = null;
  selectedVideoOnDemand = null;
  selectedliveFeed = null;
  isMP4: boolean = null;
  isDashVideo: boolean = null;
  isDashVideoOnDemand: boolean = null;
  getLinksSpinner = {};
  getLinksVideoSpinner = {};
  fromDate = new Date(new Date().setDate((new Date().getDate() - 7)));
  toDate = new Date();

  rangeFrom: any = {
    min: commonMin,
    max: commonMax,
  };

  rangeTo: any = {
    min: commonMin,
    max: commonMax,
  };

  datePicker: any = {
    from: this.fromDate,
    to: this.toDate
  };

  datePickerVideo: any = {
    from: this.fromDate,
    to: this.toDate
  };

  timePickerVideo: any = {
    from: '12:00 am',
    to: '11:59 pm'
  };

  loadingEvents = false;
  loadingVideos = false;
  selectedPath = null;
  selectedPathVideo = null;
  videoDownload: string = 'Select One …';
  memoVideos = null;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: '#63A4FF',
    },
    dial: {
      dialBackgroundColor: '#63A4FF',
    },
    clockFace: {
      clockHandColor: '#3F51B5',
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gs: GeneralService,
    public dialogRef: MatDialogRef<DashcamDateRangeModalComponent>,
    public dialog: MatDialog,
    private dashcamService: DashcamService,
  ) {
  }

  ngOnInit() {
    this.getRangeVideos();
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  getRangeVideos() {
    let fromTemp = null;
    let toTemp = null;
    fromTemp = (this.datePicker.from._isAMomentObject) ? this.datePicker.from.unix() : Math.round(this.datePicker.from.getTime() / 1000);
    toTemp = (this.datePicker.to._isAMomentObject) ? this.datePicker.to.unix() : Math.round(this.datePicker.to.getTime() / 1000);
    const device = this.data.camDevice;
    const payload = {
      startDateTime: fromTemp,
      endDateTime: toTemp,
    };
    this.loadingEvents = true;
    this.request = true;
    this.videoOnDemand = false;
    this.liveFeed = false;
    this.isDashVideoOnDemand = false;
    this.dashcamService.getDashCode(device, payload).subscribe((resp: any) => {
      if (resp && resp.status) {
        resp.data.sort((a, b) => {
          const dateA = new Date(a.utcDateTime);
          const dateB = new Date(b.utcDateTime);
          return dateB.getTime() - dateA.getTime();
        });
        this.events = resp.data;
      }
      this.loadingEvents = false;
      this.request = false;
    });
  }

  convertTo24Hour(timeString) {
    const date = new Date(`01/01/2025 ${timeString}`);
    return date.toLocaleTimeString('en-US',
      {hour12: false});
  }

  convertTo12Hour(timeString) {
    const date = new Date(`01/01/2025 ${timeString}`);
    return date.toLocaleTimeString('en-US',
      {hour12: true});
  }

  pad(toPad, padChar, length) {
    return (String(toPad).length < length)
      ? new Array(length - String(toPad).length + 1).join(padChar) + String(toPad)
      : toPad;
  }

  formatDate(date) {
    let day = date.getDate();
    day = this.pad(day, 0, 2);
    let month = date.getMonth() + 1;
    month = this.pad(month, 0, 2);
    const year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }

  step() {
    let start = 20;
    return setInterval(function () {
      console.log(start--);
    }, 1000);
  }

  getRangeVideosOndemand() {
    this.videoOnDemand = true;
    this.liveFeed = false;
    this.loadingEvents = true;
    this.request = true;
    this.isDashVideoOnDemand = false;
    const device = this.data.camDevice;
    let fromTemp = null;
    const _this = this;
    let toTemp = null;
    fromTemp = (this.datePickerVideo.from._isAMomentObject) ? this.datePickerVideo.from._d : this.datePickerVideo.from;
    toTemp = (this.datePickerVideo.to._isAMomentObject) ? this.datePickerVideo.to._d : this.datePickerVideo.to;
    const dateStringFrom = Math.round(new Date((fromTemp.getFullYear()) +
      '-' + this.pad(fromTemp.getMonth() + 1, 0, 2) + '-' +
      this.pad(fromTemp.getDate(), 0, 2) + 'T' +
      this.convertTo24Hour(this.timePickerVideo.from)).valueOf()) / 1000;
    const dateStringTo = Math.round(new Date((toTemp.getFullYear()) + '-' +
      this.pad(toTemp.getMonth() + 1, 0, 2) + '-' +
      this.pad(toTemp.getDate(), 0, 2) + 'T' +
      this.convertTo24Hour(this.timePickerVideo.to)).valueOf()) / 1000;
    const payload = {
      startDateTime: dateStringFrom,
      endDateTime: dateStringTo,
    };
    this.dashcamService.getDeviceStatus(device).subscribe((resp: any) => {
      if (resp.status) {
        this.gs.openFleetrMsg({msg: 'DASHCAM_TEXT.DEVICE_ONLINE'});
        this.dashcamService.uploadVideoFiles(device).subscribe((resp1: any) => {
          if (resp1.status) {
            let start = 7;
            this.gs.openFleetrMsg({msg: translationPipe.transform('DASHCAM_TEXT.REQUEST_UPLOAD_VIDEO') + start + 's.', duration: 7000});
            const updateTimer = document.getElementsByTagName('app-alert-snack-bar');
            const temp = setInterval(function () {
              start--;
              updateTimer[0].innerHTML = translationPipe.transform('DASHCAM_TEXT.REQUEST_UPLOAD_VIDEO') + start + 's.';
            }, 1000);
            setTimeout(function () {
              clearInterval(temp);
              _this.dashcamService.getFilesAvailables(device, payload).subscribe((resp2: any) => {
                if (resp2 && resp2.status) {
                  resp2.data.sort((a, b) => {
                    const dateA = new Date(a.datetime);
                    const dateB = new Date(b.datetime);
                    return dateB.getTime() - dateA.getTime();
                  });
                  const rows = [];
                  const unique = [];
                  for (const fruit of resp2.data) {
                    const x = new Date(fruit.datetime);
                    fruit.videoDate = _this.formatDate(x);
                    fruit.videoTime = _this.convertTo12Hour(fruit.datetime.split(' ')[1]);
                  }
                  _this.videos = resp2.data;
                }
                _this.loadingEvents = false;
                _this.request = false;
              });
            }, 7000);
          }
        });
      } else {
        this.gs.openFleetrMsg({msg: 'DASHCAM_TEXT.DEVICE_OFFLINE_VIDEO'});
        this.dashcamService.getFilesAvailables(device, payload).subscribe((resp2: any) => {
          if (resp2 && resp2.status) {
            resp2.data.sort((a, b) => {
              const dateA = new Date(a.datetime);
              const dateB = new Date(b.datetime);
              return dateB.getTime() - dateA.getTime();
            });
            for (const fruit of resp2.data) {
              const x = new Date(fruit.datetime);
              fruit.videoDate = this.formatDate(x);
              fruit.videoTime = this.convertTo12Hour(fruit.datetime.split(' ')[1]);
            }
            this.videos = resp2.data;
          }
          this.loadingEvents = false;
          this.request = false;
        });
      }
    });
  }

  downloadSignedUrl(filePath: string): void {
    this.getLinksSpinner = {...this.getLinksSpinner, [filePath]: true};
    this.dashcamService.getSignedUrl(this.data.camDevice.device, filePath).subscribe((resp: any) => {
      resp.data.forEach(element => {
        this.videoUrls = {...this.videoUrls, [filePath]: [...Object.values(element)]};
      });
      this.getLinksSpinner[filePath] = false;
      this.selectedPath = filePath;
      if (this.videoUrls[filePath]?.length > 0) {
        this.isDashVideo = true;
        this.isDashVideoOnDemand = false;
        this.isMP4 = !!this.videoUrls[filePath].find(url => url.includes('.mp4'));
        this.selectedVideo = (this.videoUrls[filePath]) ?
          (this.isMP4) ? this.videoUrls[filePath].filter(url => url.includes('.mp4'))[0] :
          this.videoUrls[filePath].filter(url => url.includes('.ts'))[0] : '';
        this.videoDownload = 'Select One …';
      } else {
        this.gs.openFleetrMsg({msg: 'DASHCAM_TEXT.NO_VIDEO_PLAYER'});
        this.isDashVideo = null;
        this.isDashVideoOnDemand = null;
        this.back();
      }
    });
  }
  getSignedUrlVideoOnDemand(fileName: string, filePath: string){
    this.dashcamService.getSignedUrlVideoOnDemand([filePath]).subscribe((respVideo: any) => {
      if (respVideo && respVideo.status) {
        respVideo.data.forEach(element => {
          this.videoOnDemandUrls = {...this.videoOnDemandUrls, [filePath]: [...Object.values(element)]};
        });
        this.getLinksVideoSpinner[fileName] = false;
        this.selectedPathVideo = filePath;
        this.selectedPath = true;
        this.videoOnDemand = false;
        if (this.videoOnDemandUrls[filePath]?.length > 0) {
          this.isDashVideoOnDemand = true;
          this.isDashVideo = false;
          this.isMP4 = !!this.videoOnDemandUrls[filePath].find(url => url.includes('.mp4'));
          this.selectedVideoOnDemand = (this.videoOnDemandUrls[filePath].length) ?
            (this.isMP4) ? this.videoOnDemandUrls[filePath].filter(url => url.includes('.mp4'))[0] :
            this.videoOnDemandUrls[filePath].filter(url => url.includes('.ts'))[0] : '';
          this.videoDownload = 'Select One …';
        } else {
          this.gs.openFleetrMsg({msg: 'DASHCAM_TEXT.NO_VIDEO_PLAYER'});
          this.isDashVideoOnDemand = null;
          this.isDashVideo = null;
          this.backVideoOnDemand();
        }
      }
    });
  }
  downloadSigneVideoUrl(fileName: string, status: string, filePath: string): void {
    const device = this.data.camDevice;
    this.getLinksVideoSpinner = {...this.getLinksVideoSpinner, [fileName]: true};
    if (filePath === '') {
      this.dashcamService.getFileInServer(device, fileName).subscribe((resp: any) => {
        if (resp && resp.status) {
          this.getSignedUrlVideoOnDemand(fileName, resp.filePath);
        }
      });
    } else {
      this.getSignedUrlVideoOnDemand(fileName, filePath);
    }
  }

  getLiveFeed() {
    this.videoOnDemand = false;
    this.liveFeed = true;
    const device = this.data.camDevice;
    this.dashcamService.getLiveFeedUrl(device).subscribe((resp: any) => {
      if (resp && resp.status) {
        this.liveChannels = Object.values(resp.url);
        this.selectedliveFeed = this.liveChannels[0];
      } else {
        this.gs.openFleetrMsg({msg: 'DASHCAM_TEXT.DEVICE_OFFLINE'});
        this.videoOnDemand = false;
        this.liveFeed = false;
      }
    });
  }

  selection(url: string) {
    this.selectedVideo = url;
  }

  selectionVideo(url: string) {
    this.selectedVideoOnDemand = url;
  }

  selectionLiveChannel(url: string) {
    this.selectedliveFeed = url;
  }

  back() {
    this.selectedVideo = null;
    this.selectedliveFeed = null;
    this.selectedPath = null;
    this.selectedPathVideo = null;
    this.liveFeed = false;
    this.videoOnDemand = false;
    this.memoVideos = null;
    this.isDashVideoOnDemand = null;
    this.isDashVideo = null;
  }

  backVideoOnDemand() {
    this.selectedVideo = null;
    this.selectedliveFeed = null;
    this.selectedPath = null;
    this.liveFeed = false;
    this.videoOnDemand = true;
    this.memoVideos = null;
    this.isDashVideoOnDemand = null;
    this.isDashVideo = null;
  }

  getVideos(selectedPath: string) {
    if (this.memoVideos) {
      return this.memoVideos;
    }
    return this.memoVideos = (this.isMP4) ? this.videoUrls[selectedPath].filter(url => url.includes('.mp4')) :
      this.videoUrls[selectedPath].filter(url => url.includes('.ts'));
  }

  getVideosOnDemand(selectedPath: string) {
    return (this.isMP4) ? this.videoOnDemandUrls[selectedPath].filter(url => url.includes('.mp4')) :
      this.videoOnDemandUrls[selectedPath].filter(url => url.includes('.ts'));
  }

  sencVideoDownload(videoDownload: string) {
    if (videoDownload !== 'Select One …') {
      this.videoDownload = videoDownload;
      window.open(videoDownload, '_blank').focus();
    }
  }

  getNameVideo(videoDownload: string) {
    return videoDownload.split(/.ts\?|.mp4\?/)[0].replace('https://s3.us-east-2.amazonaws.com/video.matrack-dashcam.matrack.com/AlertMedia/MAT/', '').split('/')[1];
  }
  handleEvent() {
    this.backVideoOnDemand();
  }

  protected readonly values = values;
}

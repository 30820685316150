<mat-expansion-panel [id]="'dashcam_'+selected?.device" [expanded]="selected?.dashcam == dashcam?.device && !isFreemium"
                     (closed)="selectedDashcam(undefined, null)">
  <mat-expansion-panel-header #panelH
                              [ngClass]="{
      'selected-driver-freemium': (selected?.dashcam === dashcam?.device) && isFreemium
    }"
                              (click)="selectedDashcam(dashcam, panelH)"
                              collapsedHeight="80px" expandedHeight="80px">
    <mat-list class="entity-list">
      <mat-list-item>
        <!-- Picture -->
        <div class="current-dashcam">
          <div class="current-car">
            <p *ngIf="dashcam.current_driver?.name" class="shift verticalFlex">
              <span><img src="/assets/img/icons/drivers_grey.svg"></span>
              <span
                class="plateHeader">{{ fullName(dashcam.current_driver.name, dashcam.current_driver.lastName) | highlight: findText }}</span>
            </p>
            <p *ngIf="dashcam.current_vehicle.brand" class="shift" mat-line>
              <span class="position-svg"><img src="/assets/img/icons/autoconductor-listas.svg"></span>
              <span innerHTML="{{ dashcam?.current_vehicle.brand | highlight: findText}}"></span>&nbsp;
              <span innerHTML="{{ dashcam?.current_vehicle.model | highlight: findText}}"></span>
              <span *ngIf="dashcam.current_vehicle.year"> - </span>
              <span class="year" innerHTML="{{ dashcam?.current_vehicle.year | highlight: findText}}"></span>
            </p>
          </div>
        </div>

        <mat-icon mat-list-icon class="picture hasPicture">
          <span class="brand">Das</span>

          <span class="indicador-driver" [ngClass]="{
              'indicator-dashcam': dashcam.vehicle
            }">
          </span>
        </mat-icon>

        <div mat-line>
          <h4 class="name">
            <span innerHTML="{{ dashcam.device }}"></span>
          </h4>
          <span class="state-dashcam" [ngStyle]="{ 'color': deviceStatus === true ? '#38b6d1' : '' }">
            <span *ngIf="deviceStatus" class="active-driver">{{ 'DASHCAM_TEXT.STATUS_ONLINE' | translation }}</span>
            <span *ngIf="!deviceStatus"
                  style="color:#b3b3b3;">
              {{ 'DASHCAM_TEXT.STATUS_OFFLINE' | translation }}
            </span>
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel-header>
  <!-- Collapse content -->
  <!-- Picture -->
  <div class="current-dashcam">

    <div class="expanded-info-dashcam">
      <div class="items-expanded-dashcam">
      </div>
      <div class="items-expanded-dashcam">
        <p class="shift verticalFlex">
        </p>
        <p class="shift" mat-line>
        </p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="list-section">
      <p *ngIf="dashcam.device" class="p-splitted">
        <button class="outlined-button" mat-raised-button
                (click)="getDashCam(dashcam.device)"> {{ 'DASHCAM_TEXT.GET_VIDEOS' | translation }}
        </button>
      </p>
    </div>

    <mat-divider></mat-divider>
    <h3 class="vehicle-subheader" matSubheader>{{ 'CURRENT_VEHICLE' | translation }}</h3>
    <mat-list class="template-list">
      <h5 *ngIf="!dashcam.current_vehicle" class="noshift-user"> {{ 'NO_VEHICLE_FOR_DRIVER' | translation }}</h5>
      <mat-list-item class="current-dashcam" *ngIf="dashcam.current_vehicle">
        <div class="dash-device-associate list-hover"
             (click)="homeService.actions({id: dashcam.current_vehicle.id}, 'vehicles')">
          <mat-icon mat-list-icon style="border: none !important" class="picture hasPicture"
                    [ngStyle]="{ 'background': dashcam.current_vehicle?.picture ? 'url('+dashcam.current_vehicle?.picture+')' : '#ffe09d'}">
          <span class="noPictureDash" *ngIf="!dashcam.current_vehicle?.picture"> {{
              dashcam.current_vehicle ?
                getNameFirstLetter(dashcam.current_vehicle?.brand, dashcam.current_vehicle?.model) : ''
            }}
          </span>
          </mat-icon>
          <h4 class="name" mat-line>{{ dashcam.current_vehicle?.brand }} {{ dashcam.current_vehicle?.model }}
            {{ dashcam.current_vehicle?.year ? '- ' + dashcam.current_vehicle?.year : '' }}</h4>
          <p mat-line>
          </p>
        </div>
        <div class="posotionEditButton" *ngIf="gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
          <button class="edit-button"
                  (click)="openGeneralModal(dashcam.current_vehicle, 'edit_vehicle' )">{{ 'VEHICLE_ADD_INFO' | translation }}
          </button>
        </div>
      </mat-list-item>
    </mat-list>

    <mat-divider></mat-divider>
    <!-- driver -->
    <h3 class="vehicle-subheader" matSubheader>{{ 'CURRENT_DRIVER' | translation }}</h3>
    <mat-list class="template-list">
      <h5 *ngIf="!dashcam.current_driver" class="noshift-user"> {{ 'VEHICLE_WITHOUT_DRIVER' | translation }} </h5>
      <mat-list-item class="current-dashcam" *ngIf="dashcam.current_driver">
        <div class="dash-device-associate list-hover"
             (click)="homeService.actions({ id: dashcam.current_driver.id }, 'drivers')">
          <mat-icon mat-list-icon style="border: none !important" class="picture hasPicture"
                    [ngStyle]="{ 'background': dashcam.current_driver?.picture ? 'url('+dashcam.current_driver?.picture+')' : '#ffe09d'}">
          <span class="noPictureDash" *ngIf="!dashcam.current_driver?.picture"> {{
              dashcam.current_driver ?
                getNameFirstLetter(dashcam.current_driver?.name, dashcam.current_driver?.lastName) : ''
            }}
          </span>
          </mat-icon>
          <h4 class="name" mat-line>
            {{ fullName(dashcam.current_driver?.name, dashcam.current_driver?.lastName) }}
          </h4>

          <p mat-line>
          <span class="state-driver"
                [ngStyle]="{ 'color': dashcam.current_driver.currentStatus === 'TripDriving' ? '#38b6d1' : '' }">
            <span *ngIf="dashcam.current_driver.currentStatus === 'TripDriving'" class="driving"></span>
            <span *ngIf="dashcam.current_driver.currentStatus === 'TripDriving'"
                  class="active-vehicle">{{ 'DRIVING' | translation }}
              {{ dashcam.current_driver.movingTime }}</span>
            <span *ngIf="dashcam.current_driver.currentStatus === 'TripStopped'" style="color:#b3b3b3;">{{
                'STOPPED' | translation
              }}</span>
            <span
              *ngIf="dashcam.current_driver.currentStatus === 'Unknown' || dashcam.current_driver.currentStatus === 'Disconnected'"
              style="color:#b3b3b3;">
              {{ 'NO_SIGNAL' | translation }}
            </span>
          </span>
          </p></div>
        <div class="posotionEditButton" *ngIf="gs.checkPermission(['admin.fleetrw', 'admin.fleetmgr'])">
          <button class="edit-button"
                  (click)="openGeneralModal(dashcam.current_driver, 'edit_driver' )">{{ 'EDIT_DRIVER' | translation }}
          </button>
        </div>
      </mat-list-item>
    </mat-list>
    <div class="close-panel" (click)="closePanel()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

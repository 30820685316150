<button class="closeModalButton" (click)="confirm(false)"> x </button>
<app-performance *ngIf="showPerformance" [data]="data" class="stats"></app-performance>
<app-score-info *ngIf="data?.type === 'score_info'" [scoreType]="data?.scoreType"></app-score-info>
<app-feedback *ngIf="data?.type === 'feedback'"></app-feedback>
<app-edit-driver *ngIf="data?.type === 'edit_driver'" [data]="data" [dialogRef]="dialogRef"></app-edit-driver>
<app-edit-vehicle *ngIf="data?.type === 'edit_vehicle'" [data]="data" [dialogRef]="dialogRef"></app-edit-vehicle>
<app-delete-geofence *ngIf="data?.type === 'delete_geofence'" [data]="data" [dialogRef]="dialogRef"></app-delete-geofence>
<app-week-filter *ngIf="data?.type === 'week_filter'" [data]="data" [dialogRef]="dialogRef"></app-week-filter>
<app-delete-admin *ngIf="data?.type === 'delete_admin'" [data]="data" [dialogRef]="dialogRef"></app-delete-admin>

<app-validate-odometer-update *ngIf="data?.type === 'validate_odometer_edit'" [data]="data" [dialogRef]="dialogRef"></app-validate-odometer-update>
<app-validate-higher-odometer-update *ngIf="data?.type === 'validate_higher_odometer_edit'" [data]="data" [dialogRef]="dialogRef"></app-validate-higher-odometer-update>
<app-edit-maintenance *ngIf="data?.type === 'edit_maintenance'" [data]="data" [dialogRef]="dialogRef"></app-edit-maintenance>
<app-delete-maintenance *ngIf="data?.type === 'delete_maintenance'" [data]="data" [dialogRef]="dialogRef"></app-delete-maintenance>
<app-tag *ngIf="data?.type === 'tag'" [data]="data" [dialogRef]="dialogRef"></app-tag>

<app-modify-password *ngIf="data?.type === 'change_password'" [data]="data" [dialogRef]="dialogRef"></app-modify-password>
<app-change-password-response *ngIf="data?.type === 'change_password_response'" [success]="data?.success" [dialogRef]="dialogRef"></app-change-password-response>

<app-detail-dtcs *ngIf="data?.type === 'detail_dtcs'" [data]="data" [dialogRef]="dialogRef"></app-detail-dtcs>

<app-extensions-activate *ngIf="data?.type === 'extensions_activate'" [data]="data" [dialogRef]="dialogRef"></app-extensions-activate>
<app-extensions-activate-success *ngIf="data?.type === 'extensions_activate_success'" [data]="data" [dialogRef]="dialogRef"></app-extensions-activate-success>

<div *ngIf="data?.type === 'app_down'" class="app-down">
    <p style="color: var(--skyBlue); font-size: 20px;"><strong>{{ 'APP_DOWN.START' | translation }}</strong></p>
    <p>{{ 'APP_DOWN.DESCRIPTION' | translation }}</p>
    <p>{{ 'APP_DOWN.ACTION' | translation }}</p>
    <p><strong>{{ 'APP_DOWN.END' | translation }}</strong></p>
</div>

<app-dashcam-date-range-modal *ngIf="data?.type === 'dashcam'" [data]="data" [dialogRef]="dialogRef"></app-dashcam-date-range-modal>

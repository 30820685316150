import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { RouterModule } from '@angular/router'
import { Subscription } from 'rxjs'
import { fEventNameConventions } from '../../helpers/helpers'
import { GeneralService } from '../../services/general.service'
import { LogoutService } from '../../services/logout.service'
import { VehicleService } from '../../services/vehicle.service'
import { GeneralModalComponent } from '../general-modal/general-modal.component'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  private suscription: Subscription = new Subscription;
  @Output() runEntities = new EventEmitter<any>();
  vehicles: any;
  profileState = 'general';
  disableLogout: boolean;
  errors: any = {};
  admin: any = {};
  showSelftManagementButton = false;
  selfManagementWitheList = ['jooycar@fleetr.com', 'maganaluis2002@gmail.com']

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileComponent>,
    public dialog: MatDialog,
    private vehicleService: VehicleService,
    private logout: LogoutService,
    public router: RouterModule,
    private gs: GeneralService
  ) {
    this.suscription.add(this.vehicleService.getCurrentVehicles.subscribe(v => this.vehicles = v));
  }

  ngOnInit() {
    this.gs.getLoggedUser.subscribe((user: any) => {
      this.admin = user;
      this.showSelftManagementButton = this.selfManagementWitheList.includes(user.email);
    });
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  updateState(state) {
    if (fEventNameConventions['profile'][state]) {
      const eventName = fEventNameConventions['profile'][state];
      this.gs.logScreenEvents(eventName, eventName, eventName, 'click');
    }
    this.profileState = state;
  }

  async logOutModal() {
    this.disableLogout = true;
    this.disableLogout = await this.logout.logoutActionFn({}, this.dialogRef);
  }

  changePassword(email) {
    this.openGeneralModal(email, 'change_password');
  }

  openGeneralModal(email, type) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '30vw',
      data: { email: email, type }
    });
  }
}

import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { GeneralService } from '../../services/general.service'

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  private suscription: Subscription = new Subscription;
  // Spinner
  showSpinner: boolean;
  isProgress: boolean;
  progress = -1;
  trip: any;


  constructor(public gs: GeneralService) {
    // Spinner state
    this.suscription.add(this.gs.getSpinnerState.subscribe((data: any) => { this.showSpinner = data.show; console.log(this.showSpinner) } ));
    this.suscription.add(this.gs.getSingleTrip.subscribe((data: any) => this.trip = data ));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

}

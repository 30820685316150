import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { environment as env } from '../../../environments/environment'
import { sortGeofence } from '../../helpers/helpers'
import { FiltersPipe } from '../../pipes/filters.pipe'
import { translationPipe } from '../../pipes/translation/translation.pipe'
import { DashcamService } from '../../services/dashcam.service'
import { DevicesService } from '../../services/devices.service'
import { DriverService } from '../../services/driver.service'
import { ExtensionsService } from '../../services/extensions.service'
import { GeneralService } from '../../services/general.service'
import { GeofencesService } from '../../services/geofences.service'
import { NotificationsService } from '../../services/notifications.service'
import { RequestService } from '../../services/request.service'
import { VehicleService } from '../../services/vehicle.service'
import { WebSocketService } from '../../services/web-socket.service'
import { GeneralModalComponent } from '../general-modal/general-modal.component'

@Component({
  selector: 'app-finder-lists',
  templateUrl: './finder-lists.component.html',
  styleUrls: ['./finder-lists.component.css'],
  providers: [FiltersPipe]
})
export class FinderListsComponent implements OnInit {
  private suscription: Subscription = new Subscription;
  type: string;
  data: any;
  pageNo: number;
  loadMore: boolean;
  contexts: any[];
  wiState: boolean;
  findText: string;
  admins: any;
  devices: any[];
  drivers: any[];
  dashcams: any[];
  geofences: any[];
  notifications: any = [];
  rankingOptions: any = [];
  geoSpinner: boolean;
  scoreOptions: any = [];
  selectedGeo: any = {};
  extensions: Array<any> = [];

  tags: any[];
  vehicles: any[];
  expandIndex: number;
  sortVehicleAZ: boolean;
  expandedEntity: any = {};
  suggestions: any;
  notiPrev: string;
  notiNext: string;

  scoreInfo: any;
  unreadNotifications: number;
  wiStateExtended: boolean;

  statsOptions: any = [];
  buttonAdd: boolean;
  scoreType: string;
  historicButtons = [
    {
      type: 'security_history',
      icon: 'historical',
      name: translationPipe.transform('security_history')
    },
    {
      type: 'efficiency_history',
      icon: 'historical',
      name: translationPipe.transform('efficiency_history')
    }
  ];
  loggedUser: any = {};
  isFreemium: boolean;
  scrolledToEnd: boolean;

  constructor(
    public generalService: GeneralService,
    public vehiclesService: VehicleService,
    public driverService: DriverService,
    public dashcamService: DashcamService,
    public deviceService: DevicesService,
    public extensionsService: ExtensionsService,
    public dialog: MatDialog,
    public webSocketService: WebSocketService,
    public filter: FiltersPipe,
    public notiService: NotificationsService,
    public gs: GeofencesService,
    private rs: RequestService
  ) {
    this.generalService.getCurrentScoreType.subscribe(st => this.scoreType = st);
    // Window state observable
    this.suscription.add(this.generalService.getWiState.subscribe((data: boolean) => this.wiState = data));
    // Window type observable
    this.suscription.add(this.generalService.getWiType.subscribe((data: string) => this.type = data));
    // Window context observable
    this.suscription.add(this.generalService.getWiContext.subscribe((data: any) => {
      this.contexts = data;
      this.buttonAddFn();
      // select default ranking
      if (this.contexts && this.contexts.length === 1 && this.contexts[0].context === 'rankings' && !this.wiStateExtended) {
        const safeclick = () => {
          if (!document.querySelectorAll('.ranking-button').length) { return setTimeout(safeclick, 100); }
          const firstOption: any = document.querySelectorAll('.ranking-button')[0];
          firstOption.click();
        };
        safeclick();
      }
    }));
    // Window findText observable
    this.suscription.add(this.generalService.getWiFindText.subscribe(data => this.findText = data));
    // Toolbar buttons
    this.suscription.add(this.generalService.getToolbarButtons.subscribe(s => this.suggestions = s));
    // window state
    this.suscription.add(this.generalService.getWiData.subscribe((data: any) => {
      this.data = data;
      if (this.data && this.vehicles) {
        this[`selected_${this.data.type}`];
      }
    }));
    // this.gs.mapContext.next('list')
    this.suscription.add(this.generalService.getWiExtendedState.subscribe(data => this.wiStateExtended = data));
    this.suscription.add(this.generalService.getScoreInfoUrl.subscribe(data => this.scoreInfo = data));
    this.suscription.add(this.gs.getGeofences.subscribe(geofences => this.geofences = geofences));
    this.suscription.add(this.vehiclesService.getCurrentVehicles.subscribe(vehicles => {
      this.vehicles = vehicles
    }))
    this.suscription.add(this.driverService.getCurrentDrivers.subscribe(drivers => this.drivers = drivers));
    this.suscription.add(this.dashcamService.getCurrentDashcams.subscribe(dashcams => this.dashcams = dashcams));
    this.getEntities();
    this.suscription.add(this.driverService.getScoringDrivers().subscribe((d: any) => this.generalService.setScoreInfoUrl({ url: d.metadata.helpUrl })));
    this.statsOptions = this.generalService.statsOptions();
    this.suscription.add(
      this.generalService.getReloadEntities.subscribe((event: boolean) => {
        if (event) { this.getEntities(); }
      })
    );

    this.suscription.add(
      this.generalService.getLoggedUser.subscribe(loggedUser => {
        this.loggedUser = loggedUser;
        this.checkPlan();
      })
    );
  }

  ngOnInit() {
    this.pageNo = 0;
    this.loadMore = true;
    this.getGeofences({ pageNo: this.pageNo, trigger: 'init' });
    this.generalService.getGeofenceSort.subscribe(x => this.sortGeofence(x));
    this.gs.functonInit.subscribe(x => {
      if (x.triggered == 'Delete') { this.getGeofences(x); }
    });

  }

  checkPlan() {
    if (!this.loggedUser) { return; }
    const { tenantsInfo } = this.loggedUser;
    this.isFreemium = (tenantsInfo || []).some(ti => ti.tags.includes(env.FLEETR_BASIC_TIER));
  }

  openScoreHelp(scoreType: string) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: ['general-modal', 'score-info-modal'],
      width: '500px',
      data: { type: 'score_info', scoreType }
    });
  }

  sortGeofence(sort_by) {
    if (!sort_by) { return; }
    this.geofences = sortGeofence(sort_by.toLowerCase(), this.geofences);
  }

  filterArray(array) {
    if (!this.findText || !this.findText.length) { return array; }
    const data = this.filter.transform(array, this.findText);
    return data;
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  showSuggestions() {
    if (!this.findText && !this.contexts) {
      return false;
    }

    return this.contexts.length > 1 || (this.findText && this.findText.length === 0);
  }

  trackByFn(index, item) { return item ? item.id : index; }

  infoWindow(button) {
    this.generalService.setWindow({ state: true, type: button.type || 'list', contexts: [button] });
  }

  async getGeofences(opts: any = {}) {
    if (opts && opts.trigger === 'loadMore') {
      this.pageNo += 1;
    }
    this.geoSpinner = true;
    const itemPerPage = 15;
    const geofences = await this.generalService.asyncRequest(this.gs.getList(this.pageNo, itemPerPage));
    if (geofences['geofences'].length < 15) {
      this.loadMore = false;
    }
    if (!this.geofences.length || opts.trigger === 'init') {
      this.geofences = geofences['geofences'].sort((a, b) => b.modif - a.modif);
    } else {
      this.geofences = this.geofences.concat(geofences['geofences'].sort((a, b) => b.modif - a.modif));
    }
    this.gs.setGeofences(this.geofences);
    this.geoSpinner = false;
  }

  async getEntities() {
    this.webSocketService.initConnection();
    const vehicles = await this.vehiclesService.getAll().toPromise();
    const dashcams = await this.dashcamService.getAllDashcams().toPromise();
    const drivers = await this.driverService.getAll().toPromise();
    const devices = await this.deviceService.getAll().toPromise();
    const extensions = await this.extensionsService.getAll().toPromise();
    this.getExtensions(extensions);
    this.deviceService.setCurrentDevices(devices);
    const { scores } = await this.rs.request({
      method: 'GET',
      endpoint: 'get_score_types',
    });
    this.scoreOptions = (scores || []).concat(this.historicButtons);
    this.populateEntities(vehicles, drivers, devices, dashcams);
  }

  getExtensions(extensions) {
    this.extensions = extensions ? extensions.data : [];
  }

  populateEntities(vehicles, drivers, devices, dashcams) {
    const mappedVehicles = vehicles.map(v => {
      return Object.assign({}, v, {
        current_driver: drivers.find(d => d.uuid === v.current_driver),
        device: devices.find(d => d.uuid === v.device)
      });
    });
    this.vehiclesService.setCurrentVehicles(mappedVehicles);

    this.dashcams = dashcams.filter(c => c.dashcam !== '').map(v => {
      return Object.assign({}, v, {
        current_driver: drivers.find(d => d.uuid === v.user),
        current_vehicle: vehicles.find(v1 => v1.uuid === v.vehicle),
        current_device: devices.find(d => d.dashcam === v.device)
      });
    });
    this.dashcamService.setCurrentDashcams(this.dashcams);

    this.drivers = drivers.map(d => {
      const driver = mappedVehicles.find(v => v.current_driver && v.current_driver.uuid === d.uuid);
      return Object.assign({}, d, {
        current_vehicle: vehicles.find(v => v.uuid === d.current_vehicle),
        device: driver ? driver.device : undefined
      });
    });

    this.driverService.setCurrentDrivers(this.drivers);
    this.devices = devices.map(d => {
      return Object.assign({}, d, {
        vehicle: vehicles.find(v => v.uuid === d.vehicle)
      });
    });
  }

  selected_vehicle() {
    setTimeout(() => {
      document.getElementById('vehicle_' + this.data.plate).scrollIntoView();
      document.getElementById('scroll-window').scrollTop += -100;
    }, 0);
  }

  sortByBRand() {
    this.generalService.addSelectedClass(undefined);
    this.sortVehicleAZ = this.sortVehicleAZ ? false : true;
    if (this.sortVehicleAZ) {
      this.vehicles.sort((a, b) => a.brand.localeCompare(b.brand));
      this.dashcams.sort((a, b) => a.brand.localeCompare(b.brand));
      this.generalService.setWindow({ state: true, type: 'list', contexts: this.contexts, data: undefined });
    } else {
      this.vehicles.sort((a, b) => b.brand.localeCompare(a.brand));
      this.dashcams.sort((a, b) => b.brand.localeCompare(a.brand));
      this.generalService.setWindow({ state: true, type: 'list', contexts: this.contexts, data: undefined });
    }
  }

  checkContext(context) {
    switch (context) {
      case 'admins':
        return this.admins;
      case 'devices':
        return this.devices;
      case 'drivers':
        return this.drivers;
      case 'dashcams':
        return this.dashcams;
      case 'geofences':
        return this.geofences;
      case 'notifications':
        return this.notifications;
      case 'tags':
        return this.tags;
      case 'vehicles':
        return this.vehicles;
    }
  }

  buttonAddFn() {
    if (!this.contexts) { return; }
    this.buttonAdd = this.contexts.length === 1 && ['vehicles', 'drivers', 'dashcams'].some(c => c === this.contexts[0].context);
  }

  openCreateEntity() {
    this.generalService.setWindow({
      state: true,
      extendedState: true,
      type: 'create_entity',
      contexts: this.contexts,
      data: {
        type: this.contexts[0].context
      }
    });
  }

  onClickCreateGeofence() {
    const context = this.contexts.filter((c) =>
      /geofences/.test(c.context)
    );
    localStorage.removeItem('geoId');
    localStorage.removeItem('vehicleId');
    localStorage.removeItem('latlong');
    localStorage.removeItem('mapType');
    localStorage.removeItem('radius');
    this.generalService.setWindow({
      state: true,
      extendedState: false,
      type: 'create_geofences',
      contexts: context,
      data: null,
    });
    this.generalService.logScreenEvents('module_geofence_create', 'module_geofence_create', 'module_geofence_create', 'click');
  }

  onGEoScroll(_, geoList) {
    const items = document.querySelector('.geoList mat-list').children;
    const lastItem: any = items[items.length - 1].children[0];
    if ((geoList.scrollTop + geoList.offsetHeight + 58) >= (lastItem.offsetTop)) {
      this.scrolledToEnd = true;
    } else { this.scrolledToEnd = false; }
  }

}
